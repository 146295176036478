<template>
  <div class='dashboard'>
    <layout-header />
    <main class='dashboard__main base-content-pc'>
      <section class='dashboard__main__left'>
        <section-course
          :courseInfo='$store.state.study.courseInfo'
          :progress='calcProgress'
          v-if='judgeShowCourseInfo'
        />
        <section-lesson
          :lessonInfo='JSON.parse(JSON.stringify(this.$store.state.study.lessonInfo))'
          :progressInfo='$store.state.study.progressInfo'
          v-if='judgeShowLessonInfo'
        />
      </section>
      <section class='dashboard__main__right'>
        <!-- <a class='dashboard__main__right__space-link' href='https://discord.gg/TqB9DBCCNg' target='_blank'>
          <img :src='imageUrlDonblerSpace'>
        </a> -->
        <ul class='dashboard__main__right__card'>
          <li v-for='card in cardList' :key='card.id'>
            <button-card
              :icon='card.icon'
              :title='card.title'
              :description='card.description'
              :url='card.url'
            />
          </li>
        </ul>
      </section>
      <section>
        <modal-on-boarding
          v-if='showOnBoarding'
          :imageUrlList='imageUrlList'
          @emitClickCloseBoarding='closeOnBoarding()'
        />
        <overlay-normal
          v-if='showOnBoarding'
          black
        />
      </section>
    </main>
    <layout-footer />
  </div>
</template>

<script>
import axios from 'axios';
import LayoutHeader from '@/components/layouts/LayoutHeader.vue';
import LayoutFooter from '@/components/layouts/LayoutFooter.vue';
import SectionCourse from '@/components/molecules/dashboard/SectionCourse.vue';
import SectionLesson from '@/components/molecules/dashboard/SectionLesson.vue';
import ModalOnBoarding from '@/components/molecules/dashboard/ModalOnBoarding.vue';
import OverlayNormal from '@/components/atoms/other/OverlayNormal.vue';
import ButtonCard from '@/components/atoms/button/ButtonCard.vue';

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    SectionCourse,
    SectionLesson,
    ModalOnBoarding,
    OverlayNormal,
    ButtonCard,
  },
  data() {
    return {
      cardList: [
        // {
        //   icon: '🎟',
        //   title: '質問チケット管理',
        //   description: 'メンターへの質問に使用するチケットの枚数確認・購入ができます。',
        //   url: '/settings/tickets/question',
        // },
        // {
        //   icon: '👋',
        //   title: '質問の仕方',
        //   description: 'メンターへの質問の方法を確認できます。',
        //   url: 'https://note.com/donbler/n/n712eec45423c?magazine_key=mdbfc358debf5',
        // },
        // {
        //   icon: '🎓',
        //   title: '有料コースの始め方',
        //   description: '専門的な内容を学習できる有料コースの始め方を確認できます。',
        //   url: 'https://note.com/donbler/n/nbcd97fd352aa?magazine_key=mdbfc358debf5',
        // },
        // {
        //   icon: '📗',
        //   title: 'サービスガイド',
        //   description: 'Donblerの使い方が分かるサービスガイドを公開しています。',
        //   url: 'https://note.com/donbler/m/mdbfc358debf5',
        // },
        {
          icon: '🐧',
          title: '公式Twitter',
          description: 'Donblerの最新情報は公式Twitterをチェック！',
          url: 'https://twitter.com/Donbler_jp',
        },
      ],
      imageUrlList: [
        `${process.env.VUE_APP_S3_MATERIAL_URL}/images/onboarding/signup_1.png`,
        `${process.env.VUE_APP_S3_MATERIAL_URL}/images/onboarding/signup_2.png`,
        `${process.env.VUE_APP_S3_MATERIAL_URL}/images/onboarding/signup_3.png`,
        `${process.env.VUE_APP_S3_MATERIAL_URL}/images/onboarding/signup_4.png`,
      ],
      showOnBoarding: false,
      imageUrlDonblerSpace: `${process.env.VUE_APP_S3_MATERIAL_URL}/images/other/toppage/webp/donbler_space_banner_pc.webp`,
    };
  },
  async created() {
    await this.execLoadCourseInfo();
    const result = await this.$store.dispatch('loadLessonInfo');
    if (!result) await this.$router.push({ name: 'Error' });
    this.showOnBoarding = this.$store.state.user.signupTutorial;
    if (this.showOnBoarding) {
      this.switchOverflow();
      this.sendSignUpAnalysis();
    }
  },
  async mounted() {
    if (this.$route.query.popup === 'subscription-success') {
      this.$notify({
        title: 'コースの購読を開始しました',
        text: '早速プログラミングの学習を開始しましょう!',
        type: 'success',
        speed: 400,
        group: 'top-right',
      });
    }
  },
  methods: {
    closeOnBoarding() {
      this.showOnBoarding = false;
      this.$store.commit('setFalseSignupTutorial');
      this.switchOverflow();
    },
    async execLoadCourseInfo() {
      const result = await this.$store.dispatch('loadCourseInfo');
      if (result === 401) {
        this.$router.push({ name: 'Top' });
      } else if (!result) {
        this.$router.push({ name: 'Error' });
      }
    },
    async execLoadTicketInfo() {
      const result = await this.$store.dispatch('loadTicketInfo');
      if (result === 401) {
        this.$router.push({ name: 'Top' });
      } else if (!result) {
        this.$router.push({ name: 'Error' });
      }
    },
    switchOverflow() {
      const body = document.querySelector('body');
      if (body.style.overflow === '') {
        body.style.overflow = 'hidden';
      } else {
        body.style.overflow = '';
      }
    },
    async sendSignUpAnalysis() {
      const params = this.$store.state.analysis.signupParams;
      if (params.utm_source !== null) {
        const headers = {
          Authorization: `Bearer ${this.$store.state.user.token}`,
        };
        await axios.post(
          `${process.env.VUE_APP_HOST}/api/auth/analysis/signup`,
          params,
          { headers },
        );
      }
    },
  },
  computed: {
    judgeShowCourseInfo() {
      return this.$store.state.study.courseInfo.id !== '';
    },
    judgeShowLessonInfo() {
      return this.$store.state.study.lessonInfo.length !== 0;
    },
    lessonLimit() {
      return Number(process.env.VUE_APP_FREE_COURSE_LESSON_LIMIT);
    },
    chapterLimit() {
      return Number(process.env.VUE_APP_FREE_COURSE_CHAPTER_LIMIT);
    },
    calcProgress() {
      let completionCnt = 0;
      let lessonAllCnt = 0;
      let allLesson = JSON.parse(JSON.stringify(this.$store.state.study.lessonInfo));
      // 無料枠制御
      if (this.$store.state.study.courseInfo.id === 1 && allLesson.length !== 0) {
        allLesson = allLesson.slice(0, this.lessonLimit);
        const sliceChapterNameCnt = allLesson.slice(-1)[0].chapterName.slice(0, this.chapterLimit);
        allLesson.slice(-1)[0].chapterName = sliceChapterNameCnt;
      }

      allLesson.forEach((v) => {
        for (let i = 1; v.chapterName.length + 1 > i; i += 1) {
          const lesson = {
            categoryId: v.categoryId,
            lessonId: v.lessonId,
            // chapterIdは存在しないので新たに付与する
            chapterId: i,
          };

          if (this.$store.state.study.progressInfo
            .some((progress) => progress.categoryId === lesson.categoryId
            && progress.lessonId === lesson.lessonId
            && progress.chapterId === lesson.chapterId)) completionCnt += 1;

          lessonAllCnt += 1;
        }
      });
      const progress = Math.floor((completionCnt / lessonAllCnt) * 100);
      return Number.isNaN(progress) ? 0 : progress;
    },
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    const body = document.querySelector('body');
    body.style.overflow = '';
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.dashboard__main {
  margin: 0 auto 120px;
  display: flex;
  max-width: 100%;
}
.dashboard__main__left {
  width: 70%;
}
.dashboard__main__right {
  width: 30%;
  margin-left: 30px;
}
.dashboard__main__right__card {
  margin: 0;
  li {
    margin-top: 15px;
  }
  li:first-child {
    margin-top: 0;
  }
}

.dashboard__main__right__space-link {
  width: 100%;
  img {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 8px;
    transition: 0.2s;
  }
  img:hover {
    opacity: 0.9;
  }
}

/* 簡易レスポンシブ */
@media screen and (max-width: $breakpoint-tab) {
.dashboard__main__left {
  width: 100%
}
.dashboard__main__left__campaign a {
  font-size: 12px;
}
.dashboard__main__right {
  display: none;
}
}
</style>
