<template>
  <a
    class='button-card'
    @click='$emit("emitClick")'
    :href='url'
    target='_blank'
    rel='noopener noreferrer'
  >
    <div class='button-card__left' v-emoji>
      {{ icon }}
    </div>
    <div class='button-card__center'>
      <h3>{{ title }}</h3>
      <p>{{ description }}</p>
    </div>
    <div class='button-card__right'>
       <i class='fas fa-arrow-right' />
    </div>
  </a>
</template>

<script>

export default {
  props: {
    icon: String,
    title: String,
    description: String,
    url: String,
  },
};
</script>

<style lang="scss">
.button-card {
  background-color: $color-white;
  display: flex;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  color: $color-black;
  text-decoration: none;
}

.button-card:visited {
  color: $color-black;
}

.button-card__left {
  width: 50px;
  height: 50px;
  background-color: $color-gray;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  img {
    height: 22px;
    margin: 10px 0;
    vertical-align: middle;
  }
}

.button-card__center {
  text-align: left;
  margin: 0 15px;
  h3 {
    font-size: 14px;
    @extend .strimwidth-one;
  }
  p {
    font-size: 10px;
    @extend .strimwidth-two;
  }
}

.button-card__center:hover {
  text-decoration: underline;
  text-underline-offset:1.4px;
}
</style>
