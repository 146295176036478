<template>
  <section class='section-user-info'>
    <div class="dashboard__modal__main">
      <button class="close_icon" @click='$emit("emitClickCloseBoarding")'>
        <i class="fas fa-times"></i>
      </button>
      <div class="dashboard__modal__image">
        <img :src=imageUrlList[number] alt="">
      </div>
      <div class="dashboard__modal__slider">
        <bar-progress
          class='dashboard__modal__bar'
          :progress='calcProgress'
        />
        <div class='dashboard__modal__move'>
          <button-normal
            v-if='number > 0'
            @emitClick='prevProgress'
            white
          >
            <p>前に戻る</p>
          </button-normal>
          <button-normal
            v-if='number < lastNumber'
            @emitClick='nextProgress'
          >
            <p>次に進む</p>
          </button-normal>
          <button-normal
            v-if='number == lastNumber'
            @emitClick='$emit("emitClickCloseBoarding")'
          >
            <p>学習を開始する</p>
          </button-normal>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BarProgress from '@/components/atoms/bar/BarProgress.vue';
import ButtonNormal from '@/components/atoms/button/ButtonNormal.vue';

export default {
  props: {
    imageUrlList: Array,
  },
  components: {
    BarProgress,
    ButtonNormal,
  },
  data() {
    return {
      progress: 0,
      number: 0,
      lastNumber: Number(Object.keys(this.imageUrlList).length) - 1,
    };
  },
  computed: {
    calcProgress() {
      return (this.number / this.lastNumber) * 100;
    },
  },
  methods: {
    prevProgress() {
      this.number -= 1;
    },
    nextProgress() {
      this.number += 1;
    },
  },
};
</script>

<style lang="scss">
.dashboard__modal__main {
  position: fixed;
  z-index: 999;
  width: 600px;
  height: 400px;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
  transform:translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
}
.close_icon {
  position: absolute;
  top: 5px;
  right: 5px;
}
.dashboard__modal__image {
  height: 300px;
  img {
    width: 100%;
    height: 100%;
    background-color: $color-white;
  }
}
.dashboard__modal__slider {
  padding: 10px;
  height: 100px;
  background-color: $color-white;
}
.dashboard__modal__bar {
  margin: auto;
  padding: 10px;
  width: 200px;
}
.dashboard__modal__move {
  margin-top: 10px;
  text-align: center;
  button {
    margin: 0 10px;
  }
}
</style>
