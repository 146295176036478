<template>
  <section class='section-course'>
    <div class='section-course__left'>
      <div class='section-course__left__top'>
        <div>
          <h5>現在学習中のコース</h5>
        </div>
        <h3>{{ courseInfo.name }}</h3>
        <p>{{ courseInfo.description }}</p>
      </div>
      <bar-show-lang
        title='学習言語'
        :lang='courseInfo.lang'
      />
      <div class='section-course__left__progress'>
        <p>コース進捗</p>
        <bar-progress
          class='section-course__left__progress__bar'
          :progress='progress'
        />
        <p>{{ progress }}%</p>
      </div>
    </div>
    <img class='section-course__right' :src='setLogo'>
  </section>
</template>

<script>
import BarShowLang from '@/components/atoms/bar/BarShowLang.vue';
import BarProgress from '@/components/atoms/bar/BarProgress.vue';

export default {
  props: {
    courseInfo: Object,
    progress: Number,
  },
  components: {
    BarShowLang,
    BarProgress,
  },
  computed: {
    setLogo() {
      return process.env.VUE_APP_S3_MATERIAL_URL + this.courseInfo.logo;
    },
  },
};
</script>

<style lang="scss">
.section-course {
  display: flex;
  align-items: center;
  background-color: $color-white;
  padding: 30px 30px 15px 30px;
}

.section-course__left__top {
  margin-bottom: 10px;
  div {
    display: flex;
    align-items: center;
    h5 {
      font-size: 16px;
      font-weight: 500;
      margin-right: 15px;
    }
  }
  p {
    font-size: 14px;
    font-weight: 500;
    margin-top: 5px;
    @extend .strimwidth-three;
  }
  h3 {
    font-size: 32px;
    font-weight: 600;
    @extend .strimwidth-two;
  }
}

.section-course__left__progress {
  display: flex;
  align-items: center;
  margin-top: 10px;
  p {
    font-size: 12px;
    font-weight: 500;
    width: 90px;
  }
}

.section-course__left__progress__bar {
  padding: 10px;
}

.section-course__right {
  width: 210px;
  height: 210px;
  margin-left: 20px;
}

/* 簡易レスポンシブ */
@media screen and (max-width: $breakpoint-tab) {
.section-course__right {
  display: none;
}
}
</style>
