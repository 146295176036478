<template>
  <section class='section-lesson'>
    <ul class='section-lesson__map'>
      <li
        class='section-lesson__map__list'
        v-for='(lesson, index) in filterLessonInfo' :key='lesson.id'
      >
        <div class='section-lesson__map__list__left' />
        <div class='section-lesson__map__list__center' v-if='index < filterLessonInfo.length -1'/>
        <div class='section-lesson__map__list__right'>
          <h5>Lesson{{ index + 1 }}: {{ lesson.lessonName }}</h5>
          <p>{{ lesson.lessonDescription }}</p>
          <ul>
            <li v-for='(chapter, index) in lesson.chapterName' :key='index'>
              <button-dashboard-chapter
                :check='judgeCheck(lesson.categoryId, lesson.lessonId,index + 1)'
                :categoryId='lesson.categoryId'
                :lessonId='lesson.lessonId'
                :chapterId='index + 1'
              >
                  <span>{{ index+1 + '. ' + chapter }}</span>
              </button-dashboard-chapter>
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <div class='section-lesson__recommend-paid' v-if='judgeShowRecommendPaid'>
      <div class='section-lesson__recommend-paid__left' v-emoji>
        👋
      </div>
      <div class='section-lesson__recommend-paid__right'>
        <h3>続きは有料コースで学習！</h3>
        <p>
          無料コースで受講できるのはここまでです。より実践的な内容は有料コースでチャレンジできます！
          もっと挑戦してみたいならまずは1ヶ月試してみませんか？有料コース一覧は
          <router-link :to='{ name: "SettingCourses" }'>こちらから</router-link>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import ButtonDashboardChapter from '@/components/atoms/button/ButtonDashboardChapter.vue';

export default {
  props: {
    lessonInfo: Array,
    progressInfo: Array,
  },
  components: {
    ButtonDashboardChapter,
  },
  computed: {
    judgeShowRecommendPaid() {
      return this.$store.state.study.courseInfo.id === 1;
    },
    lessonLimit() {
      return Number(process.env.VUE_APP_FREE_COURSE_LESSON_LIMIT);
    },
    chapterLimit() {
      return Number(process.env.VUE_APP_FREE_COURSE_CHAPTER_LIMIT);
    },
    filterLessonInfo() {
      // 無料枠制御
      if (this.$store.state.study.courseInfo.id === 1) {
        const lesson = this.lessonInfo.slice(0, this.lessonLimit);
        const sliceChapterNameCnt = lesson.slice(-1)[0].chapterName.slice(0, this.chapterLimit);
        lesson.slice(-1)[0].chapterName = sliceChapterNameCnt;
        return lesson;
      }
      return this.lessonInfo;
    },
  },
  methods: {
    judgeCheck(categoryId, lessonId, chapterId) {
      const target = this.progressInfo.filter((v) => v.categoryId === categoryId
        && v.lessonId === lessonId
        && v.chapterId === chapterId);
      return target.length !== 0;
    },
  },
};
</script>

<style lang="scss">
.section-lesson {
  background-color: $color-white;
  padding: 30px 30px 30px;
}

.section-lesson__map {
  margin: 0;
}

.section-lesson__map__list {
  display: flex;
  margin-bottom: 40px;
  position: relative;
}

.section-lesson__map__list:last-child {
  margin-bottom: 0;
}

.section-lesson__map__list__left {
  background-color: $color-white;
  border: 5px solid $color-yellow;
  border-radius: 50px;
  width: 25px;
  height: 25px;
  margin: 2px 15px 0 0;
  flex-shrink: 0;
  z-index: 2;
}

.section-lesson__map__list__center {
  border-right: 5px solid $color-yellow;
  height: calc(100% + 40px);
  top: 15px;
  left: 10px;
  position: absolute;
}

.section-lesson__map__list__right {
  h5 {
    font-size: 16px;
    font-weight: 600;
    @extend .strimwidth-one;
  }
  p {
    font-size: 12px;
    margin: 5px 0 10px;
    @extend .strimwidth-three;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 8px;
  }
}

.section-lesson__recommend-paid {
  display: flex;
  padding: 20px;
  margin-top: 30px;
  background-color: $color-light-gray;
  border-radius: 3px;
}

.section-lesson__recommend-paid__left {
  margin-right: 20px;
  img {
    width: 40px;
  }
}

.section-lesson__recommend-paid__right {
  h3 {
    font-size: 16px;
  }
  p {
    margin-top: 5px;
    font-size: 12px;
  }
  a {
    color: $color-blue;
    font-weight: 600;
    text-decoration: none;
  }
  a:visited {
    color: $color-blue;
  }
  a:hover {
    text-decoration: underline;
    text-underline-offset: 1px;
  }
}
</style>
